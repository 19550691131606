import $ from 'jquery';

// import Swiper JS
import Swiper from 'swiper';

// import Lightbox2
import lightbox2 from 'lightbox2';

document.addEventListener('DOMContentLoaded', () => {
  //blog RSS
  $(function() {
    $.get('/blog/feed/rss2', function(data) {
      $(data).find('item').each(function() {
        var el = $(this);
        var title = el.find('title').text();
        var description = el.find('description').text();
        var link = el.find('link').text();
        var category = el.find('category').text();

        var pubDate = el.find('pubDate').text();
        var timestamp = new Date(pubDate);
        var y = timestamp.getFullYear();
        var m = ('00' + (timestamp.getMonth() + 1)).slice(-2);
        var d = ('00' + timestamp.getDate()).slice(-2);
        var date = y + '.' + m + '.' + d;

        $('#js-rss').append(
          '<tr><th>' + date + '</th>' +
          '<td><a href="' + link + '">' + title + '</a></td></tr>'
        );
      });
    });
  });

  //swiper
  const swiper = new Swiper('.swiper-container', {
    slidesPerView: 'auto',
    centeredSlides: true,
    spaceBetween: 0,
    loop: true,
    speed: 2000,

    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
  });

  //navi
  var current = false;
  var $gBtn = $('#js-headerMenu');
  var $gNav = $('#js-headerNav');

  //sp
  $gBtn.on('click', function () {
    if (current == false) {
      current = true;
      if (!$(this).hasClass('is-close')) {
        $gNav.stop().addClass('is-enable');
        $gBtn.addClass('is-close');
        current = false;
      } else {
        $gNav.stop().removeClass('is-enable');
        $gBtn.removeClass('is-close');
        current = false;
      }
    } else {
      return false;
    }
  });

  //sp tel link
  if (navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/)) {
    $('.js-tel').each(function () {
      var str = $(this).html();
      if ($(this).children().is('img')) {
        $(this).html($('<a>').attr('href', 'tel:' + $(this).children().attr('alt').replace(/-/g, '')).append(str + '</a>'));
      } else {
        $(this).html($('<a>').attr('href', 'tel:' + $(this).text().replace(/-/g, '')).append(str + '</a>'));
      }
    });
  }
})
